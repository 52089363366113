/** @jsx jsx */
import { jsx, Box, Container, Flex } from 'theme-ui'
import { graphql } from 'gatsby'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { RichText } from 'prismic-reactjs'
import Img from 'gatsby-image'

import {
  Layout,
  Main,
  Section,
} from '@giraldomac/gatsby-theme-mindmerge/src/components/layout'

const FcoiPolicy = () => {
  const image = `/metaimage.png`

  return (
    <Layout>
      <GatsbySeo
        title="FCOI Policy"
        titleTemplate="%s | ExesaLibero Pharma"
        description=" "
        openGraph={{
          images: [
            {
              url: `${image}`,
              width: 800,
              height: 600,
              alt: 'Og Image Alt',
            },
          ],
        }}
      />

      <Main>
        {/* Main */}
        <Section id="fcoi">
          <Container sx={{ px: '1em' }}>
            <Flex sx={{ flexWrap: 'wrap', mx: [0, -3] }}>
              <Box sx={{ px: [0, 3], width: ['full'] }}>
                <h1>FINANCIAL CONFLICTS OF INTEREST </h1>
              </Box>
              <Box sx={{ px: [0, 3], width: ['full', '3/4'] }}>
                <h2>SECTION 1: PURPOSE & SCOPE.</h2>
                <ul>
                  <li>
                    1.1. ExesaLibero Pharma, Inc (ExesaLibero) seeks to ensure
                    that all ExesaLibero Employees and Officials, including
                    Investigators and Staff, are responsible for sustaining the
                    highest ethical standards through values of integrity,
                    honesty, and fairness in their research, service, and
                    business practices.
                  </li>
                  <li>
                    1.2. This policy is to comply with 42 CFR 50.604 as required
                    by Federal Regulations. As such, any violations of these
                    policies will be reported the U.S. Public Health Service
                    (NIH).
                  </li>
                </ul>
                <h2>
                  SECTION 2: CONFLICT OF INTEREST DEFINED; and CORRECTIVE
                  ACTIONS FOR VIOLATIONS OF THIS RULE.
                </h2>{' '}
                <p>
                  <strong>2.1 Conflict of Interest Defined.</strong>
                </p>
                <ul>
                  <li>
                    2.1.1 A Conflict of Interest occurs when there is a
                    divergence between a ExesaLibero Employee’s private,
                    personal relationships or interests and their professional
                    obligations to the ExesaLibero such that a reasonable
                    observer might question whether the individual’s
                    professional actions or decisions are determined by or
                    substantially altered by considerations of personal benefit,
                    gain, or advantage.
                  </li>
                  <li>
                    2.1.2 Whether a Conflict of Interest or the appearance of a
                    Conflict of Interest exists depends on the situation, not on
                    the character or actions of the individual. The
                    determination of the existence of a Conflict of Interest or
                    the appearance of a Conflict of Interest is done objectively
                    on a case-by-case basis. Because the appearance of a
                    Conflict of Interest can be as damaging or detrimental as an
                    actual Conflict of Interest, for the purposes of this Rule,
                    apparent Conflicts of Interest are treated the same as
                    actual Conflicts of Interest. As a result, any appearance of
                    a Conflict of Interest must also be disclosed and reviewed
                    in accordance with this Rule by the Signing Official.
                  </li>
                </ul>
                <p>
                  <strong>
                    2.2 Corrective Actions for Violations of this Rule.
                  </strong>
                </p>{' '}
                <ul>
                  <li>
                    2.2.1 Any ExesaLibero Employee who fails to abide by the
                    requirements within this Rule shall be subject to
                    appropriate disciplinary action, including warning,
                    suspension, termination, or other disciplinary action as may
                    be appropriate.
                  </li>{' '}
                  <li>
                    2.2.3 Furthermore, although conduct may not violate this
                    Rule, it may still be prohibited by the ExesaLibero under a
                    different rule, policy, or standard of behavior.
                    Accordingly, in such cases, the ExesaLibero reserves the
                    ability to take any necessary action.
                  </li>{' '}
                </ul>
                <h2>SECTION 3: CONFLICT OF INTEREST IN RESEARCH.</h2>
                <ul>
                  <li>
                    3.1 A Conflict of Interest in Research may exist where a
                    Significant Financial Interest for Research could directly
                    affect or reasonably appear to affect the design, conduct,
                    or reporting of research or other activity sponsored by an
                    outside entity.
                  </li>{' '}
                  <li>
                    3.2 <strong>Disclosure Requirements.</strong> All
                    Investigators of any ExesaLibero research project must
                    disclose any Significant Financial Interests for Research to
                    the ExesaLibero. All Investigators must submit a Conflict of
                    Interest in Research Disclosure at least annually.
                    <ul>
                      <li>
                        3.2.1 All Investigators must submit a Conflict of
                        Interest in Research Disclosure regardless of whether or
                        not he or she has a Significant Financial Interest for
                        Research to report.
                      </li>
                      <li>
                        3.2.2 All Investigators must submit and certify the
                        disclosure as accurate and complete.
                      </li>
                      <li>
                        3.2.3. All Investigators must update their disclosure
                        within thirty (30) days of the development of a new
                        Significant Financial Interest for Research.
                      </li>
                      <li>
                        3.2.4 If an Investigator has not previously submitted an
                        annual Conflict of Interest in Research Disclosure, the
                        Investigator must submit a Conflict of Interest in
                        Research Disclosure prior to submitting a research
                        proposal seeking external funds or otherwise
                        participating in any research activity regardless of the
                        source offunding.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <strong>
                      3.3 Review of Conflict of Interest in Research.
                    </strong>
                    <ul>
                      <li>
                        3.3.1 Conflict of Interest in Research Disclosures
                        submitted by Investigators shall be reviewed by
                        ExesaLibero Signing Official. He shall determine if an
                        Investigator has a Conflict of Interest in Research or
                        the appearance of a Conflict of Interest in Research. If
                        a Conflict is identified, he may determine that the
                        Conflict can be managed through an appropriate
                        Management Plan. If he determines that the Conflict
                        cannot be managed, then it shall recommend steps to
                        resolve the Conflict.
                      </li>
                    </ul>
                  </li>{' '}
                </ul>
                <h2>SECTION 4: OTHER CONFLICTS OF INTEREST.</h2>
                <ul>
                  <li>
                    4.1 To the extent a ExesaLibero Employee develops a real or
                    perceived Conflict of Interest that is not expressly covered
                    by this Rule, the ExesaLibero Employee must disclose this
                    Conflict to their immediate supervisor within thirty (30)
                    days of the development of the Conflict.
                  </li>
                  <li>
                    4.2 The ExesaLibero Employee’s supervisor shall report the
                    disclosed Conflict of Interest to Human Resources (for
                    Staff) or the President’s Office (for Investigators) to
                    develop with the supervisor an appropriate Management Plan.
                  </li>

                  <li>
                    4.3 ExesaLibero Employees are required to fully comply with
                    all disclosure requirements of third parties for any
                    activity that requires a disclosure of a Conflict of
                    Interest. Such disclosures may be required when engaging in
                    various activities, including but not limited to seeking
                    research grants, participating in speaking engagements, or
                    contributing to a publication.
                  </li>
                </ul>
                <h2>SECTION 5: RECORD KEEPING</h2>
                <ul>
                  <li>
                    5.1 As required by 42 CFR 50.604 of the Federal Regulations,
                    all Conflict of Interest records will be retained for a
                    period of three years.
                  </li>
                </ul>
                <h2>SECTION 6: DEFINITIONS.</h2>
                <ul>
                  <li>
                    6.1 “Conflict of Interest in Research Disclosure” means a
                    form developed to disclose the potential Conflicts of
                    Interest in Research held by ExesaLibero Investigators
                    developed by the Office of the President of ExesaLibero.
                  </li>{' '}
                  <li>6.2 “Employee” means any Investigator and Staff.</li>
                  <li>
                    6.3 “Family Member” means spouse, a person with whom the
                    individual is living with as a partner, any dependent child,
                    dependent grandchild, or dependent parent.
                  </li>
                  <li>
                    6.4 “Institutional Conflict of Interest Disclosure” means a
                    form developed to disclose the potential institutional
                    conflicts of interest of ExesaLibero Officials. The
                    disclosure form for all employees is available as a
                    downloadable file on this website.
                  </li>
                  <li>
                    6.5 “Investigator” means the project director or principal
                    investigator/program director, coinvestigator, collaborator,
                    senior/key personnel, and any other person, regardless of
                    title or position, who is responsible for the design,
                    conduct, reporting, or proposing of research or other
                    activity that is sponsored by an extramural agency or
                    enterprise. Postdoctoral scholars and fellows may be
                    considered Investigators if designated as such by the
                    President on a case-by-case basis.
                  </li>
                  <li>
                    6.6 “Management Plan” means an agreed plan to take action to
                    address a Conflict of Interest, which may include
                    eliminating the conflict, to ensure, to the extent possible,
                    that the Employee’s actions do not violate ExesaLibero
                    Policies, this Rule, or any other relevant laws,
                    regulations, policies, or procedures.
                  </li>
                  <li>
                    6.7 “President” means the ExesaLibero President or their
                    designee. The Signing Official for ExesaLibero is the
                    ExesaLibero President or their designee.
                  </li>
                  <li>
                    6.8 “Significant Financial Interest for Research” means a
                    financial interest consisting of one or more of the
                    following interests of an Investigator or Family Member of
                    the Investigator that reasonably appears related to the
                    Investigator’s institutional responsibilities:
                  </li>
                  <ul>
                    <li>
                      6.8.1.1 With regard to any publicly traded entity, the
                      value of any remuneration received from the entity in the
                      12 months preceding the disclosure and the value of any
                      equity interest in the entity as of the date of
                      disclosure, that when aggregated exceeds Five Thousand
                      Dollars ($5,000.00).
                    </li>

                    <li>
                      6.8.2 With regard to any non-publicly traded entity, the
                      value of any remuneration received from the entity during
                      the 12 months preceding the disclosure, that when
                      aggregated exceeds Five Thousand Dollars ($5,000.00), or
                      when the Investigator or Family Member holds any equity
                      interest in the entity.
                    </li>

                    <li>
                      6.8.3 Any intellectual property rights and interests
                      (e.g., patents, copyrights), upon receipt of income
                      related to such rights and interests.
                    </li>

                    <li>
                      6.8.4 The term Significant Financial Interest for Research
                      does not include the following types of financial
                      interests: salary, royalties, or other remuneration paid
                      by the ExesaLibero to the Investigator or family member if
                      the Investigator is currently employed or otherwise
                      appointed by the ExesaLibero; income from seminars,
                      lectures, or teaching engagements sponsored by a Federal,
                      state, or local government agency; or income from service
                      on advisory committees or review panels for a Federal,
                      state, or local government agency.
                    </li>
                  </ul>
                  <li>
                    6.9 “Staff” means all employees, not previously defined as
                    an Investigator.
                  </li>
                  <li>
                    6.10 “ExesaLibero Official” means ExesaLibero President and
                    Vice Presidents.
                  </li>
                </ul>
              </Box>
              <Box sx={{ px: [0, 3], width: ['full', '1/4'] }}>
                <h2>Forms</h2>
                <Box as="ul" sx={{ margin: 0, padding: 0 }}>
                  <li>
                    <a href="https://prismic-io.s3.amazonaws.com/exesalibero/c39eb802-1c9b-4411-979a-462d5234eda3_ExesaLibero+-+Financial+Conflict+of+Interest+Training+and+Screening+Form.pdf">
                      Financial Conflict of Interest Training and Screening Form
                    </a>
                  </li>
                  <li>
                    <a href="https://prismic-io.s3.amazonaws.com/exesalibero/bdb74087-0132-408f-bc5b-442631db02f2_ExesaLibero+-+Financial+Conflict+of+Interest+Disclosure+Form.pdf">
                      Financial Conflict of Interest Disclosure Form
                    </a>
                  </li>
                </Box>
              </Box>
            </Flex>
          </Container>
        </Section>
      </Main>
    </Layout>
  )
}

export default FcoiPolicy
